import React, { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage, FormattedList } from 'react-intl';
import {
  toMoneyValue,
  ComputedPaymentTypeValues,
  DEFAULT_MAX_CART_SIZE,
} from '@ads-bread/shared/bread/util';
import { IntroCard } from '../../components/IntroCard';
import { Installments } from '../../components/svgs/Installments';
import { useFormattedNumber } from '../../lib/hooks/useFormattedNumber';
import IntroScreen, {
  formattingValues,
  IntroTitle,
  InstallmentsCardProps,
} from '../../components/pages/intro/Intro';
import { Disclaimer } from '../../components/pages/intro/Disclaimers';
import { RouteMachineServiceScreenProps } from '../../components/RouteMachineService';
import { MarketingPromo } from '../../components/MarketingPromo';
import { Button } from '../../components/Button';
import { EligibilityCheckHelp } from '../../components/EligibilityCheckHelp';
import { useMerchantConfig } from '../../components/MerchantContext';

type DisclaimersProps = {
  installmentsPaymentProduct: 0 | ComputedPaymentTypeValues | null;
};

const Disclaimers: FunctionComponent<DisclaimersProps> = ({
  installmentsPaymentProduct: installmentsPaymentTypeValues,
}) => {
  const { aprFormatter, formatCurrency } = useFormattedNumber();
  const { disclosureSymbolInstallments } = useMerchantConfig();
  return (
    <div data-testid="intro-disclaimer">
      <Disclaimer>
        {installmentsPaymentTypeValues && (
          <FormattedMessage
            defaultMessage="{installmentsSymbol} Available for purchases between {minCartSize} and {maxCartSize}. Subject to approval of credit application. Rates
          range from {minInterestRateBPS} to {maxInterestRateBPS} APR, resulting in, for example, {promoOfferLength}
          monthly payments of {promoOfferAmount} at {promoOfferRate} APR, per {exampleTotalPrice} borrowed. Loans made by RBC Ampli Inc."
            description="RBC disclosure with installment only and product price / PDP"
            values={{
              ...formattingValues,
              minCartSize: formatCurrency(
                toMoneyValue(installmentsPaymentTypeValues.minCartSize)
              ),
              maxCartSize: formatCurrency(
                toMoneyValue(installmentsPaymentTypeValues.maxCartSize || 0)
              ),
              minInterestRateBPS: aprFormatter(
                installmentsPaymentTypeValues.minInterestRateBPS
              ),
              maxInterestRateBPS: aprFormatter(
                installmentsPaymentTypeValues.maxInterestRateBPS
              ),
              promoOfferAmount: formatCurrency(
                installmentsPaymentTypeValues.promoOfferAmount
              ),
              promoOfferRate: aprFormatter(
                installmentsPaymentTypeValues.promoOfferRate
              ),
              exampleTotalPrice: formatCurrency(
                installmentsPaymentTypeValues.exampleTotalPrice
              ),
              promoOfferLength: installmentsPaymentTypeValues.promoOfferLength,
              installmentsSymbol: disclosureSymbolInstallments,
            }}
          />
        )}
      </Disclaimer>
    </div>
  );
};

const InstallmentsCard: FunctionComponent<InstallmentsCardProps> = ({
  paymentTypeValues,
  installmentIcon,
}) => {
  const { disclosureSymbolInstallments } = useMerchantConfig();
  const { formatCurrency } = useFormattedNumber();
  if (!paymentTypeValues) {
    return null;
  }

  return (
    <IntroCard
      icon={installmentIcon}
      className="mt-11"
      dataTestId="installments-card"
    >
      <h3 className="text-lg font-extrabold leading-normal mt-5 mb-2 uppercase">
        <FormattedMessage
          defaultMessage="Instalments"
          description="RBC Intro screen installments card heading"
        />
      </h3>
      <div className="text-base font-bold">
        <FormattedMessage
          defaultMessage="Flexible monthly payment plans"
          description="Intro screen installments card sub heading with default order"
          values={formattingValues}
        />
      </div>
      <ul className="list-none list-inside flex flex-col items-center text-sm leading-normal">
        <li className="mt-4 text-base font-normal">
          <FormattedMessage
            defaultMessage="{lengthValues} {lengthSize,
                       plural,
                        =0 {No}
                        one {month}
                        other {month}
                      }
                     payment {lengthSize,
                       plural,
                        =0 {plans}
                        one {plan}
                        other {plans}
                      }
                    available<sup>{disclosureSymbolInstallments}</sup>"
            description="RBC Intro screen installments card bullet one"
            values={{
              ...formattingValues,
              lengthValues: (
                <FormattedList
                  value={Array.from(new Set(paymentTypeValues.lengthOptions))}
                  type="conjunction"
                />
              ),
              lengthSize: paymentTypeValues.lengthOptions.length,
              disclosureSymbolInstallments,
            }}
          />
        </li>
        <li className="mt-4 text-base font-normal">
          <FormattedMessage
            defaultMessage="Transparent rates and no hidden fees"
            description="RBC Intro screen installments card bullet two"
          />
        </li>

        <li className="mt-4 text-sm text-dark-blue-grey font-normal">
          <FormattedMessage
            defaultMessage="Available for purchases between {minCartSize} and {maxCartSize}"
            description="Intro screen installments card bullet three"
            values={{
              ...formattingValues,
              minCartSize: formatCurrency(
                toMoneyValue(paymentTypeValues.minCartSize)
              ),
              maxCartSize: formatCurrency(
                toMoneyValue(
                  paymentTypeValues.maxCartSize ?? DEFAULT_MAX_CART_SIZE
                )
              ),
            }}
          />
        </li>
      </ul>
    </IntroCard>
  );
};

export const Intro: FunctionComponent<RouteMachineServiceScreenProps> = (
  props
) => {
  return (
    <IntroScreen {...props}>
      {({ synthesizedInstallmentsPaymentValues }) => (
        <>
          <IntroTitle>
            <FormattedMessage
              defaultMessage="Pay Over Time"
              description="Intro screen heading"
            />
          </IntroTitle>
          <h2 className="text-2xl leading-normal text-center">
            <FormattedMessage
              defaultMessage="PayPlan by RBC Loans"
              description="RBC Intro screen sub heading"
            />
          </h2>
          {synthesizedInstallmentsPaymentValues && (
            <InstallmentsCard
              paymentTypeValues={synthesizedInstallmentsPaymentValues}
              installmentIcon={Installments}
            />
          )}

          <div className="bg-merchant bg-opacity-20 text-center my-8 px-1 font-normal">
            <FormattedMessage
              defaultMessage="Checking your eligibility is quick, easy,
          and won’t affect your credit <NoWrapTooltip>score</NoWrapTooltip>"
              description="Intro screen body"
              values={{
                NoWrapTooltip: (chunks: ReactNode) => (
                  <span className="whitespace-nowrap">
                    {chunks}

                    {EligibilityCheckHelp && (
                      <EligibilityCheckHelp dataTestID="intro-eligibility-check-help" />
                    )}
                  </span>
                ),
              }}
            />
          </div>
          <Button onClick={props.forward}>
            <FormattedMessage
              defaultMessage="Get Started"
              description="Intro screen button text"
            />
          </Button>
          {MarketingPromo && <MarketingPromo />}
          <Disclaimers
            installmentsPaymentProduct={synthesizedInstallmentsPaymentValues}
          />
        </>
      )}
    </IntroScreen>
  );
};

export default Intro;
